<template>
    <div class="animated fadeIn">
        <b-card>
            <b-card-header>
                <div class="row">
                    <div class="col-9 col-sm-11">
                        <h5><b>ISS Permissions</b></h5>
                    </div>
                    <div class="col-3 col-sm-1">
                        <div class="row" style="float: right">
                            <div class="col-2">
                                <div
                                    v-if="permissionsSelected.length !== 0 && (usersSelected.length !== 0 || groupsSelected.length !== 0)">
                                    <fa-icon :icon="['fas', 'trash']" @click="deleteSelected"/>
                                </div>
                            </div>
                            <div class="col-2">
                                <div
                                    v-if="permissionsSelected.length !== 0 && (usersSelected.length !== 0 || groupsSelected.length !== 0)">
                                    <fa-icon :icon="['fas', 'square-plus']" @click="addSelected"></fa-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-card-header>
            <br>
            <div class="row">
                <div class="col">
                    <v-client-table style="width: 100%" ref="userTable" :columns="userColumns" :data="users"
                                    :options="userOptions" :theme="theme" class="dataTable">
                        <b-form-checkbox slot-scope="props" slot="select" v-model="usersSelected" :value="props.row._id"
                                         style="float: right"></b-form-checkbox>
                        <div slot="h__select">
                            <b-form-checkbox style="float: right" type="checkbox" v-model='userAllMarked'
                                             @change="usersSelected = unmarkAndMarkAll(usersSelected, userAllMarked, users)"></b-form-checkbox>
                        </div>
                    </v-client-table>
                </div>
                <div class="col">
                    <v-client-table style="width: 100%" ref="groupTable" :columns="groupColumns" :data="groups"
                                    :options="groupOptions" :theme="theme" class="dataTable">
                        <b-form-checkbox slot-scope="props" slot="select" v-model="groupsSelected"
                                         :value="props.row._id" style="float: right"></b-form-checkbox>
                        <div slot="h__select">
                            <b-form-checkbox style="float: right" type="checkbox" v-model='groupAllMarked'
                                             @change="groupsSelected = unmarkAndMarkAll(groupsSelected, groupAllMarked, groups)"></b-form-checkbox>
                        </div>
                    </v-client-table>
                </div>
            </div>
            <br/>
            <v-client-table @row-click="rowClicked" style="width: 100%" ref="permissionsTable"
                            :columns="permissionsColumns" :data="permissions" :options="permissionsOptions"
                            :theme="theme" class="dataTable">
                <b-form-checkbox slot-scope="props" slot="select" v-model="permissionsSelected" :value="props.row._id"
                                 style="float: right"></b-form-checkbox>
                <div slot="h__select">
                    <b-form-checkbox style="float: right" type="checkbox" v-model='permissionsAllMarked'
                                     @change="permissionsSelected = unmarkAndMarkAll(permissionsSelected, permissionsAllMarked, permissions)"></b-form-checkbox>
                </div>
                <div slot="usernames" slot-scope="props">
          <span v-for="(i, index) in props.row.usernames" :key="i">
            {{ i }}<span v-if="index!==props.row.usernames.length-1">, </span>
          </span>
                </div>
                <div slot="groups" slot-scope="props">
          <span v-for="(i, index) in props.row.groups" :key="i">
            {{ i }}<span v-if="index!==props.row.groups.length-1">, </span>
          </span>
                </div>
            </v-client-table>
            <b-tooltip v-if="tooltipRender" ref="tooltip" :target="target" :title="tooltipTitle"
                       triggers=""></b-tooltip>
        </b-card>
    </div>
</template>

<script>
import iss from '../../services/iss.js'
import Vue from 'vue'
import {ClientTable} from 'vue-tables-2'

Vue.use(ClientTable);

export default {
    data: () => {
        return {
            tooltipTitle: "",
            tooltipRender: true,
            target: '',
            permissions: [],
            users: [],
            groups: [],
            permissionsSelected: [],
            usersSelected: [],
            groupsSelected: [],
            perPage: 10,
            errorMsg: {method: "", status: "", statusText: ""},

            //Data Table

            useVuex: false,
            theme: 'bootstrap4',
            template: 'default',
            //Data Table For User
            userAllMarked: false,
            userColumns: ['select', '_id', 'username', 'type'],
            userOptions: {
                orderBy: {column: 'username', ascending: true},
                headings: {
                    _id: 'User ID',
                    username: 'Username',
                    type: 'User Type',
                    select: ' '
                },
                sortable: ['_id', 'username', 'type'],
                filterable: ['_id', 'username', 'type'],
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            //Data Table For Group
            groupAllMarked: false,
            groupColumns: ['select', '_id', 'groupname'],
            groupOptions: {
                orderBy: {column: 'groupname', ascending: true},
                headings: {
                    _id: 'Group ID',
                    groupname: 'Group name',
                    select: ' '
                },
                sortable: ['_id', 'groupname'],
                filterable: ['_id', 'groupname'],
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            //Data Table For Permissions
            permissionsAllMarked: false,
            permissionsColumns: ['select', 'url', 'request', 'groups', 'usernames'],
            permissionsOptions: {
                orderBy: {column: 'url', ascending: true},
                headings: {
                    url: 'Permission URL',
                    request: 'Request Type',
                    groups: 'Groups',
                    usernames: 'Usernames',
                },
                sortable: ['url', 'request', 'groups', 'usernames'],
                filterable: ['url', 'request', 'groups', 'usernames'],
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
        }
    },
    beforeCreate() {
        iss.listPermissions().then(response => {
            this.permissions = response.data;
        }).catch(error => {
            this.$mToast({
                title: error.response.status + ' Error',
                text: "Permissions couldn't be retrieved: " + error.response.statusText,
                style: 'error'
            });
        });

        iss.getUsersInGroup().then(response => {
            this.users = response.data;
        }).catch(error => {
            this.$mToast({
                title: error.response.status + ' Error',
                text: "Users couldn't be retrieved: " + error.response.statusText,
                style: 'error'
            });
        });

        iss.getAllGroups().then(response => {
            this.groups = response.data;
        }).catch(error => {
            this.$mToast({
                title: error.response.status + ' Error',
                text: "Groups couldn't be retrieved: " + error.response.statusText,
                style: 'error'
            });
        });
    },
    methods: {
        unmarkAndMarkAll(selected, allMarked) {
            if (allMarked === false) {
                let temp = [];
                if (selected === this.permissionsSelected) {
                    if (this.$refs.permissionsTable.allFilteredData) {
                        this.$refs.permissionsTable.allFilteredData.forEach(function (item) {
                            temp.push(item._id);
                        });
                    }
                } else if (selected === this.groupsSelected) {
                    if (this.$refs.groupTable.allFilteredData) {
                        this.$refs.groupTable.filteredData.forEach(function (item) {
                            temp.push(item._id);
                        });
                    }
                } else {
                    if (this.$refs.userTable.allFilteredData) {
                        this.$refs.userTable.allFilteredData.forEach(function (item) {
                            temp.push(item._id);
                        });
                    }
                }
                return (temp)
            } else {
                return ([])
            }
        },
        addSelected() {
            iss.addPermissions(this.usersSelected, this.groupsSelected, this.permissionsSelected).then(response => {
                this.groupsSelected = [];
                this.usersSelected = [];
                this.permissionsSelected = [];

                iss.listPermissions().then(response => {
                    this.$mToast({
                        title: 'Success',
                        text: "Permissions updated",
                        style: 'success'
                    });
                    this.permissions = response.data;
                }).catch(error => {
                    this.$mToast({
                        title: error.response.status + ' Error',
                        text: "Permissions couldn't be retrieved: " + error.response.statusText,
                        style: 'error'
                    });
                });

            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Permissions couldn't be updated: " + error.response.statusText,
                    style: 'error'
                });
            });
        },
        deleteSelected() {
            iss.removePermissions(this.usersSelected, this.groupsSelected, this.permissionsSelected).then(() => {
                this.groupsSelected = [];
                this.usersSelected = [];
                this.permissionsSelected = [];
                iss.listPermissions().then(response => {
                    this.$mToast({
                        title: 'Success',
                        text: "Permissions removed",
                        style: 'success'
                    });
                    this.permissions = response.data;
                }).catch(error => {
                    this.$mToast({
                        title: error.response.status + ' Error',
                        text: "Permissions couldn't be retrieved: " + error.response.statusText,
                        style: 'error'
                    });
                });

            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Permissions couldn't be deleted: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        getRowCount(items) {
            return items.length
        },
        rowClicked(item) {
            //ctrl click to make tooltip appear with id
            if (item.event.ctrlKey) {
                this.tooltipRender = false;
                this.tooltipTitle = item.row._id;
                item.event.target.id = item.row._id;
                this.target = item.row._id;
                //restart tooltip so it can map to its new position
                Vue.nextTick().then(() => {
                    this.tooltipRender = true;
                    Vue.nextTick().then(() => {
                        this.$refs.tooltip.$emit('open')
                        setTimeout(function () {
                            //make the tooltip disapear automatically
                            this.$refs.tooltip.$emit('close');
                        }.bind(this), 4000)
                    });
                });
            }
        },
    }
}
</script>

